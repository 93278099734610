import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useUserProfile } from '../../../context/userTokens';

export const useSupport = () => {
  const { boot, hide, show, isOpen } = useIntercom();
  const { profile } = useUserProfile();
  const [booted, setBooted] = useState(false);

  useEffect(() => {
    if (!profile) {
      console.warn('No profile found, booting intercom with defaults');
      boot({ hideDefaultLauncher: true });
      setBooted(true);
      return;
    }

    console.info('Booting intercom with profile', profile);

    const { id, name, email, image, orgId } = profile;
    boot({
      userId: id,
      name,
      email,
      avatar: { type: 'avatar', imageUrl: image },
      company: { companyId: orgId },
      hideDefaultLauncher: true,
      actionColor: '#7f56d9',
      backgroundColor: '#E9D7FE',
    });
  }, [profile, show, hide, boot, booted]);

  return {
    show,
    hide,
    isOpen,
    toggle: () => (isOpen ? hide() : show()),
  };
};
