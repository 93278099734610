import styles from './ErrorFallback.module.scss';

import { useSupport } from '../../app/_components/Support/intercom';
import { Button } from '../Button/Button';

import SvgWarningRed from '@/icons/components/WarningRed';

const ErrorFallback = () => {
  const support = useSupport();
  return (
    <div className={styles.container}>
      <div className={styles.error}>
        <SvgWarningRed />
        <div className={styles.header}>Something went wrong...</div>
        <div className={styles.text}>
          We had some trouble loading this page. Please refresh the page or get
          in touch for support.
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={() => support.toggle()}
            className={styles.supportButton}
            label="Contact support"
            backgroundColor="transparent"
          />
          <Button onClick={() => location.reload()} label="Refresh" />
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
