import classNames from 'classnames';
import React, { FC } from 'react';

import style from './Button.module.css';

interface ButtonProps {
  backgroundColor?: string;
  disabled?: boolean;
  label: string;
  className?: string;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({
  label,
  backgroundColor,
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      style={{ backgroundColor }}
      className={classNames(style.button, className)}
      {...props}
    >
      {label}
    </button>
  );
};
