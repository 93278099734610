import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { getCookie } from './cookie';

const ID_TOKEN_KEY = 'id_token';
const GIST_ORG_KEY = 'custom:gist_organization';

export interface UserProfile {
  id: string;
  orgId: string;
  email: string;
  image: string;
  name: string;
}

export interface IdToken {
  sub?: string;
  [GIST_ORG_KEY]?: string;
  name?: string;
  picture?: string;
  email?: string;
}

export const getIdToken = (): string | undefined => {
  return getCookie(ID_TOKEN_KEY) as string | undefined;
};

export const getUserProfile = async (): Promise<UserProfile | null> => {
  const idToken = getIdToken();
  if (!idToken) {
    return null;
  }

  try {
    const profile = parseIdToken(idToken);
    return profile;
  } catch (e) {
    console.error(`Failed to parse id token: ${e}`);
    return null;
  }
};

export const parseIdToken = (idToken: string) => {
  const payload = jwtDecode(idToken) as Record<string, string>;
  return {
    id: payload.sub,
    orgId: payload[GIST_ORG_KEY],
    name: payload.name,
    image: payload.picture,
    email: payload.email,
  } as UserProfile;
};

export const useUserProfile = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const user = await getUserProfile();
        setProfile(user);
      } catch (err) {
        console.error(`Failed to get user profile: ${err}`);
      }

      setLoading(false);
    };
    updateUser();
  }, []);

  return {
    profile,
    loading,
  };
};
